@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* background variables */
    --bg-main: #ffffff;
    --bg-primary: #249eb1;
    --bg-secondary: #249eb1;
    --bg-info: #f5f5f5;
    --bg-error: #b9379226;
    --bg-dark: #e9e9e9;
    --bg-body: #f5f5f5;
    --bg-disabled: #e9ecef;
    --bg-loaderBack: #ffffff66;
    --bg-selected: #249eb166;

    /* primary variables */
    --primary-main: #249eb1;
    --primary-light: #fff;
    --primary-dark: #000;
    --primary-disabled: rgb(153, 153, 153);
    --primary-secondary: #a9a9a9;

    --success-main: #00c537;

    --hover-main: #ff8a32;
  }

  .dark {
    /* background variables */
    --bg-main: #0f2231;
    --bg-primary: #4d8bed;
    --bg-secondary: #0faac0;
    --bg-info: #153043;
    --bg-error: rgba(185, 55, 146, 0.15);
    --bg-dark: #5c636a;
    --bg-body: rgba(21, 48, 67, 0.95);
    --bg-disabled: #727888;
    --bg-loaderBack: rgba(0, 0, 0, 0.3);
    --bg-selected: #4d8bed;

    /* primary variables */
    --primary-main: #4d8bed;
    --primary-light: rgb(29, 32, 33);
    --primary-dark: #fff;
    --primary-disabled: #d2d2d2;
    --primary-secondary: #e9e9e9;

    --success-main: #00c537;

    --hover-main: #ff8a32;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
}

html {
  overflow-y: hidden;
}
.MuiMenu-paper {
  /* width: 100px !important; */
  max-height: 200px !important;
  background-color: #ffffff !important;
  margin-top: 6px !important;
  border: 1px solid #f5f5f5 !important;
  border-radius: 8px !important;
  -webkit-box-shadow: -3px 4px 5px -5px rgba(34, 60, 80, 0.6) !important;
  -moz-box-shadow: -3px 4px 5px -5px rgba(34, 60, 80, 0.6) !important;
  box-shadow: -3px 4px 5px -5px rgba(34, 60, 80, 0.6) !important;
}
.MuiDataGrid-cell {
  border: 0 !important;
}

.MuiDataGrid-row {
  border-width: 1px;
}

.MuiDataGrid-columnHeaders {
  border-bottom: none !important;
}

.MuiButtonBase-root {
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: inherit !important;
}
.Toastify__toast {
  width: 560px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}
.Toastify__close-button {
  width: 35px;
  height: 35px;
  margin-top: 9px;
  margin-right: 12px;
}

.date_lable {
  font-size: 14px;
  font-weight: 500;
}

.MuiAutocomplete-popper * {
  /* background-color: red; */
  z-index: 9999;
}
.darkBlue {
  color: #0065ff;
  font-weight: 700;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.delete_table_btn {
  width: 32px !important;
  height: 32px !important;
  border-radius: 10px !important;
  background-color: rgba(227, 102, 131, 0.2) !important;
}

.edit_icon_btn {
  width: 32px !important;
  height: 32px !important;
  border-radius: 10px !important;
  background-color: #f5f5f5 !important;
}
.ant-modal-close-x {
  background: none !important;
}

.ant-modal-close-x .MuiIconButton-root {
  width: 40px;
  height: 40px;
  margin-left: -10px !important;
  margin-top: -10px !important ;
  border-radius: 10px;
}
.ant-modal-close-x .MuiIconButton-root svg path {
  stroke: none;
}
